import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Mail, Phone, ShoppingCart } from 'react-feather'

interface Props {
  email?: string
  IBEID?: number
  languageCode: string
  languagePrefix: string | null
  phone?: string
}

export const Toolbars = memo(function Toolbars({
  languageCode,
  languagePrefix,
  email,
  phone,
  IBEID,
}: Props) {
  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      dial={5}
      row
      space="between"
      className={scrollDir == 'down' ? 'hidden' : ''}
    >
      {email ? (
        <Item
          href={`mailto: ${email}`}
          onClick={() => {
            typeof window.gtag !== 'undefined' &&
              window.gtag('event', 'Click', {
                event_category: 'Website',
                event_label: 'Email Address',
              })
          }}
        >
          <Mail />
          <Label>{useVocabularyData('email', languageCode)}</Label>
        </Item>
      ) : null}
      {phone ? (
        <Item
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Mobile Phone',
                })
            }
          }}
        >
          <Phone />
          <Label>{useVocabularyData('telephone', languageCode)}</Label>
        </Item>
      ) : null}
      {IBEID ? (
        <Item
          href={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
            languagePrefix ? languagePrefix : 'en'
          }`}
          rel="noopener"
          target="_blank"
        >
          <ShoppingCart />
          <Label>{useVocabularyData('book', languageCode)}</Label>
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 60px;
  padding: 0.5625rem 3rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }
`

const Item = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  text-align: center;

  &:last-of-type {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
    svg {
      stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }

  svg {
    width: auto;
    height: 1.375rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark4};
    stroke-width: 2;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: 0.1875rem;
`
